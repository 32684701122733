import React from 'react';
import Typography from '@material-ui/core/Typography';
import {StepNavigator, StepProps} from '../../event-registration-flow';
import {useRegisterContactDetailsForCompetitionMutation} from "../../mutations.generated";
import {useWeeklyRegistrationContext} from "../../../../../contexts";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery} from "@material-ui/core";
import {FieldCustomValue, FieldValue} from "@queensland-running/qr-components";
import moment from "moment";

export const WeeklyRegistrationConfirmation = ({handleNext, handleBack, competitionId, competitionDay}: StepProps) => {
  const {contactDetails, runnerDetails} = useWeeklyRegistrationContext();
  const [registerRunnersForEvent, { loading }] = useRegisterContactDetailsForCompetitionMutation({
    onCompleted() {
      handleNext!();
    },
  });
  const m = useMediaQuery("(max-width: 960px)")

  const handleSubmit = () => {
    registerRunnersForEvent({
      variables: {
        input: {
          competitionId,
          username: contactDetails?.emailAddress,
          contactDetails: {
            firstName: contactDetails?.firstName ?? "",
            lastName: contactDetails?.lastName ?? "",
          },
          runnerDetails: runnerDetails
            .map((runner) => ({
              id: runner.id,
              bibNumber: runner.bibNumber,
              club: runner.club,
              firstName: runner.firstName,
              lastName: runner.lastName,
              gender: null,
              dateOfBirth: moment(runner.dateOfBirth).isValid() ? runner.dateOfBirth : null,
            }))
        },
      },
    });
  };

  return (
    <>
      <Typography variant="h5" color="primary">
        Confirm
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldValue label="Email Address" name="emailAddress" value={contactDetails?.emailAddress}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="First Name" name="firstName" value={contactDetails?.firstName}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="Last Name" name="lastName" value={contactDetails?.lastName}/>
        </Grid>

        <Grid item xs={12}>
          {
            m ?
            <FieldCustomValue label="Runners" name='runners' CustomValue={() =>
              <>
              {
                runnerDetails.map((detail, index) =>
                  <Grid container key={index}>
                    <Grid item xs={12}><Typography variant='h5' style={{marginTop: '10px', fontWeight: 'bold'}}>Runner {detail["index"] + 1}</Typography></Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldValue label="Name" name="name" value={[detail.lastName, detail.firstName].join(', ')}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldValue label="Date of Birth" name="dob" value={detail['dateOfBirth'] &&
                        `${detail['dateOfBirth'] && moment(detail['dateOfBirth']).format('DD/MM/YYYY')} (${moment(competitionDay).diff(
                          detail['dateOfBirth'],
                          'years',
                        )} y/o)`}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldValue label="Bib Number" name="bib" value={detail.bibNumber}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldValue label="Club/School/Independent" name="lastName" value={detail.club}/>
                    </Grid>
                  </Grid>)
              }
              </>
            }/>
              :(<FieldCustomValue label="Runners" name="runners" CustomValue={() =>
              <Table>
                <TableHead><TableRow><TableCell>Name</TableCell><TableCell>Date of
                  Birth</TableCell><TableCell>Bib</TableCell><TableCell>Club</TableCell></TableRow></TableHead>
                <TableBody>
                  {
                    runnerDetails.map((detail, index) =>
                      <TableRow key={index}><TableCell>{detail.lastName}, {detail.firstName}</TableCell><TableCell>{detail['dateOfBirth'] &&
                        `${detail['dateOfBirth'] && moment(detail['dateOfBirth']).format('DD/MM/YYYY')} (${moment(competitionDay).diff(
                          detail['dateOfBirth'],
                          'years',
                        )} y/o)`}</TableCell><TableCell>{detail.bibNumber}</TableCell><TableCell>{detail.club}</TableCell></TableRow>
                    )
                  }
                </TableBody>
              </Table>
            }/>)
          }

        </Grid>
      </Grid>
      <br/>
      <br/>
      <Typography>Note: Age is calculated based on the day of the event.</Typography>
      <br/>
      <StepNavigator
        handleBack={handleBack}
        backText={'Back'}
        nextDisabled={loading}
        handleNext={handleSubmit}
        nextText={'Next'}
      />
    </>
  );
};
